import React from "react";
import Card from "./Card";

function Results() {
  return (
    <>
      <section className="page-section portfolio" id="results">
        <div className="container">
          {/* //Portfolio Section Heading */}
          <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
            Results
          </h2>
          {/* //Icon Divider */}
          <div className="divider-custom">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon">
              <i className="fas fa-star"></i>
            </div>
            <div className="divider-custom-line"></div>
          </div>
          {/* <!-- Portfolio Grid Items--> */}
          <div className="row justify-content-center">
            <Card
              src={"assets/img/results/result-1.jpg"}
              title={"2017"}
              target={"#portfolioModal1"}
              id={"portfolioModal1"}
            />
            <Card
              src={"assets/img/results/result-2.jpg"}
              title={"2018"}
              target={"#portfolioModal2"}
              id={"portfolioModal2"}
            />

            <Card
              src={"assets/img/results/result-3.jpg"}
              title={"2020"}
              target={"#portfolioModal3"}
              id={"portfolioModal3"}
            />
            <Card
              src={"assets/img/results/result-4.jpg"}
              title={"2016"}
              target={"#portfolioModal4"}
              id={"portfolioModal4"}
            />
            <Card
              src={"assets/img/results/result-5.jpg"}
              title={"2019"}
              target={"#portfolioModal5"}
              id={"portfolioModal5"}
            />
            <Card
              src={"assets/img/results/result-6.jpg"}
              title={"2017"}
              target={"#portfolioModal6"}
              id={"portfolioModal6"}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Results;
