import React from "react";

function About() {
  return (
    <section className="page-section about-us  text-white mb-0" id="about">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-white">
          About Us
        </h2>

        <div className="divider-custom divider-light">
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="divider-custom-line"></div>
        </div>

        <div className="row">
          <div className="col-lg-4 ms-auto">
            <p className="lead">
             NANDINI PUBLIC SCHOOL is established in 1995 to impart quality education,
             This is a complete school comprising Nursery, Primary and High School for
             both boys and girls, have qualified and dedicated faculity at this School
             to help the Kids in achieving  academic brilliance.
            </p>
          </div>
          <div className="col-lg-4 me-auto">
            <p className="lead">
              There fore our School seeks co-opertion of parents and considered them as
              members of NANDINI family in creating a just and to the growth of knowledge,
              good conduct, of the children.
              <p style={{color: "",
            fontFamily: "cursive"
            }}>DISCIPLINE, DEDICATION AND DEVOTION are the pillers of NANDINI.</p>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
