import React from "react";

function Footer() {
  return (
    <div>
      <footer
        style={{ backgroundColor: "#00BFFF" }}
        class="footer text-center footer-edits "
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-4 mb-5 mb-lg-0">
              <h4 class="text-uppercase mb-4">Location</h4>
              <p class="lead mb-0">
                NANDINI PUBLIC SCHOOL.
                <br />
                4th Block,Nandini Layout,
                <br />
                Karnataka, Bengaluru-560096
                <br />
                Phone: 080 3582 4775
                <br />
              </p>
            </div>

            <div class="col-lg-8 mb-5 mb-lg-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d62198.44754240731!2d77.4974932581055!3d13.009991002273685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bae3d0ea98addaf%3A0x26965baa1ca9d07c!2s2G8J%2BGXF%20Nandini%20Public%20School.%20NANDINI%20Layout%2C%204th%20Block%2C%20Kanteerava%20Nagar%2C%20Nandini%20Layout%2C%20Bengaluru%2C%20Karnataka%20560096!3m2!1d13.016305!2d77.5324119!5e0!3m2!1sen!2sin!4v1643112720688!5m2!1sen!2sin"
                width="70%"
                height="250"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </footer>
      <div className="row design-footer">
        <div className="col-12">
          <p>
            &copy; Designed and Developed by:
            <a
              href="https://github.com/Dhanushchandra"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <strong>Dhanush C</strong>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
