import React from "react";
import Card from "./Card";

function Achivements() {
  return (
    <>
      <section className="page-section portfolio" id="achivements">
        <div className="container">
          {/* //Portfolio Section Heading */}
          <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
            Achivements
          </h2>
          {/* //Icon Divider */}
          <div className="divider-custom">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon">
              <i className="fas fa-star"></i>
            </div>
            <div className="divider-custom-line"></div>
          </div>
          {/* <!-- Portfolio Grid Items--> */}
          <div className="row justify-content-center">
            <Card
              src={"assets/img/achivements/29.jpg"}
              title={""}
              target={"#portfolioModala1"}
              id={"portfolioModala1"}
            />
            <Card
              src={"assets/img/achivements/achive-1.jpg"}
              title={""}
              target={"#portfolioModala2"}
              id={"portfolioModala2"}
            />
            <Card
              src={"assets/img/achivements/28.jpg"}
              title={""}
              target={"#portfolioModala3"}
              id={"portfolioModala3"}
            />
            <Card
              src={"assets/img/achivements/05.jpg"}
              title={""}
              target={"#portfolioModala4"}
              id={"portfolioModala4"}
            />
            <Card
              src={"assets/img/achivements/30.jpg"}
              title={""}
              target={"#portfolioModala5"}
              id={"portfolioModala5"}
            />
            <Card
              src={"assets/img/achivements/01.jpg"}
              title={""}
              target={"#portfolioModala6"}
              id={"portfolioModala6"}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Achivements;
