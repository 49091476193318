import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Results from "./components/Results";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Achivements from "./components/Achivements";
import Events from "./components/Pages/Events";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Header />
                <Achivements />
                <About />
                <Results />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route path="/events" element={<Events />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
