import React from "react";
import Card from "./Cards";

function Events() {
  return (
    <>
      <section className="page-section portfolio" id="achivements">
        <div className="container">
          {/* //Portfolio Section Heading */}
          <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">
            Events
          </h2>
          {/* //Icon Divider */}
          <div className="divider-custom">
            <div className="divider-custom-line"></div>
            <div className="divider-custom-icon">
              <i className="fas fa-star"></i>
            </div>
            <div className="divider-custom-line"></div>
          </div>
          {/* <!-- Portfolio Grid Items--> */}
          <div className="row justify-content-center">
            <Card
              src={"assets/img/Events/19.jpg"}
              title={""}
              target={"#portfolioModale1"}
              id={"portfolioModale1"}
            />
            <Card
              src={"assets/img/Events/02.jpg"}
              title={""}
              target={"#portfolioModale2"}
              id={"portfolioModale2"}
            />
            <Card
              src={"assets/img/Events/03.jpg"}
              title={""}
              target={"#portfolioModale3"}
              id={"portfolioModale3"}
            />
            <Card
              src={"assets/img/Events/08.jpg"}
              title={""}
              target={"#portfolioModale4"}
              id={"portfolioModale4"}
            />
            <Card
              src={"assets/img/Events/09.jpg"}
              title={""}
              target={"#portfolioModale5"}
              id={"portfolioModale5"}
            />
            <Card
              src={"assets/img/Events/10.jpg"}
              title={""}
              target={"#portfolioModale6"}
              id={"portfolioModale6"}
            />
            <Card
              src={"assets/img/Events/11.jpg"}
              title={""}
              target={"#portfolioModale7"}
              id={"portfolioModale7"}
            />
            <Card
              src={"assets/img/Events/13.jpg"}
              title={""}
              target={"#portfolioModale8"}
              id={"portfolioModale8"}
            />
            <Card
              src={"assets/img/Events/14.jpg"}
              title={""}
              target={"#portfolioModale9"}
              id={"portfolioModale9"}
            />
            <Card
              src={"assets/img/Events/16.jpg"}
              title={""}
              target={"#portfolioModale10"}
              id={"portfolioModale10"}
            />
            <Card
              src={"assets/img/Events/15.jpg"}
              title={""}
              target={"#portfolioModal11"}
              id={"portfolioModale11"}
            />
            <Card
              src={"assets/img/Events/17.jpg"}
              title={""}
              target={"#portfolioModale12"}
              id={"portfolioModale12"}
            />
            <Card
              src={"assets/img/Events/18.jpg"}
              title={""}
              target={"#portfolioModale13"}
              id={"portfolioModale13"}
            />
            <Card
              src={"assets/img/Events/24.jpg"}
              title={""}
              target={"#portfolioModale14"}
              id={"portfolioModale14"}
            />
            <Card
              src={"assets/img/Events/27.jpg"}
              title={""}
              target={"#portfolioModale15"}
              id={"portfolioModale15"}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
